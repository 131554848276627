import { Collapse, Typography, Row, Col } from "antd";
import {
  CaretDownOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import "./CareersDetail.scss";
import { useTranslation } from "react-i18next";
import { dispatch, useSelector } from "../../redux/store";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getOnecareer } from "../../redux/slices/career";
import ReactHtmlParser from "react-html-parser";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import moment from "moment";

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface DescriptionItem {
  title: string;
  content: string;
}

const CareersDetail = () => {
  const { careerDetail } = useSelector((state) => state.career);
  const [arrayKey, setArrayKey] = useState<string | string[]>([]);
  const params = useParams<{ id: any }>();
  const { t } = useTranslation();

  let content = "";
  let img = "";
  let description: DescriptionItem[] = [];

  const isValidJson = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  };

  if (typeof careerDetail.content === "string" && isValidJson(careerDetail.content)) {
    const dataObj = JSON.parse(careerDetail.content);
    content = dataObj.content;
    img = dataObj.thumbnail;
    if(Array.isArray(dataObj.detail)){
      description = dataObj.detail.filter((item:any) => item.title !== "" && item.content !== "");
    }
  }

  
  useEffect(() => {
    if (params) {
      dispatch(getOnecareer(params.id));
    }
  }, [params]);

  const handleCollapseChange = (activeKey: string | string[]) => {
    setArrayKey(activeKey);
  };

  const isActivePanel = (index: number) => arrayKey.includes((index + 1).toString());

  const renderCareerDetails = () => (
    <>
      <Title level={2} className="title careersDetail__title" data-aos="fade-up" data-aos-duration="700">
        {careerDetail.name}
      </Title>
      <div className="careersDetail__date" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
        {moment(careerDetail.createdAt).format("DD-MM-YYYY")}
      </div>
      <div className="careersDetail__date" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
        {careerDetail.field}, {t(`${careerDetail.jobType}`)}
      </div>
      <div className="careersDetail__desc" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
        {careerDetail.description}
      </div>
      <div className="careersDetail__content" data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">
        {ReactHtmlParser(isValidJson(careerDetail.content) ? content : careerDetail.content)}
      </div>
      <Collapse
        ghost
        onChange={handleCollapseChange}
        expandIconPosition="end"
        className="panelJobOffer"
        expandIcon={({ isActive }) => (isActive ? <CaretDownOutlined /> : <CaretRightOutlined />)}
      >
        {description.filter((item) => item.title || item.content).map((item, index) => (
          <Panel
            className="panelJobOffer__item"
            header={
              <div className={`panelJobOffer__header ${isActivePanel(index) ? "active" : ""}`}>
                {item.title}
              </div>
            }
            key={index + 1}
          >
            <p>{item.content}</p>
          </Panel>
        ))}
      </Collapse>
    </>
  );

  return (
    <div className="careersDetail">
      <BreadcrumbHeader />
      {img ? (
        <Row
          justify="space-between"
          align="middle"
          className="jobDetail"
          gutter={[{ xs: 0, xl: 140 }, { xs: 24, xl: 0 }]}
        >
          <Col
            xs={24}
            xl={8}
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-duration="700"
          >
            <div className="jobDetail__image">
              <div className="shine-effect">
                <img src={img} alt="Job" />
              </div>
            </div>
          </Col>
          <Col xs={24} xl={16}>
            {renderCareerDetails()}
          </Col>
        </Row>
      ) : (
        <div >{renderCareerDetails()}</div>
      )}
    </div>
  );
};

export default CareersDetail;
