import React from 'react';
import { Helmet } from 'react-helmet';
import { META_DATA_DEFAULT } from "../../../../src/constants/local";
export interface MetaData {
  title: string;
  description: string;
  keywords?: string;
  author?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogUrl?: string;
}

interface MetaLayoutProps {
  metaData: MetaData;
}


const MetaLayout: React.FC<MetaLayoutProps> = ({ metaData }) => {
  const {
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
  } = metaData;

  return (
    <Helmet>
      <title>{title || META_DATA_DEFAULT.TITLE}</title>
      <meta name="description" content={description || META_DATA_DEFAULT.DESCRIPTION} />
      <meta name="keywords" content={keywords || META_DATA_DEFAULT.KEY_WORDS} />
      <meta name="author" content={META_DATA_DEFAULT.AUTHOR} />
      <meta itemProp='name' content={title || META_DATA_DEFAULT.TITLE} />
      <meta itemProp='description' content={description || META_DATA_DEFAULT.DESCRIPTION} />
      <meta itemProp='image' content={ogImage || META_DATA_DEFAULT.IMAGE} />
      {/* Open Graph tags */}
      <meta property="og:title" content={ogTitle || META_DATA_DEFAULT.TITLE} />
      <meta property="og:description" content={ogDescription || META_DATA_DEFAULT.DESCRIPTION} />
      <meta property="og:image" content={ogImage || META_DATA_DEFAULT.IMAGE} />
      <meta property="og:url" content={ogUrl || META_DATA_DEFAULT.URL} />
    </Helmet>
  );
};


export default MetaLayout;
